import { useMemo } from 'react'

import useDelayedPayment from '@pages/Checkout/hooks/Payment/Common/useDelayedPayment'
import useHoldReservation from '@pages/Checkout/hooks/Payment/Common/useHoldReservation'
import { PaymentProvider } from '@pages/Checkout/hooks/Payment/useBookingPayment'

export const useCommonMethods = (): PaymentProvider => {
  const cash = useDelayedPayment({ type: 'cash' })
  const terminal = useDelayedPayment({ type: 'terminal' })
  const invoice = useDelayedPayment({ type: 'invoice' })
  const holdReservation = useHoldReservation()

  return useMemo(() => ({ cash, terminal, invoice, holdReservation }), [cash, holdReservation, invoice, terminal])
}
