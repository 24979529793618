import React, { ReactElement } from 'react'

import LogoLink from '@components/Logo/Link'
import PoweredByDistribusion from '@components/Logo/PoweredByDistribusion'
import { useSettings } from '@queries/settings'

import '@components/Logo/index.scss'

interface LogoProps {
  showPoweredByDistribusionLabel?: boolean
}

const Logo = ({ showPoweredByDistribusionLabel }: LogoProps): ReactElement | null => {
  const [{ logo }] = useSettings()
  const { url } = logo

  if (!url) return null

  return (
    <div className="partner-logo">
      <LogoLink>
        <>
          <img src={url} className="logo-image" alt="Logo" data-tag="logo" />
          {showPoweredByDistribusionLabel && <PoweredByDistribusion />}
        </>
      </LogoLink>
    </div>
  )
}

export default Logo
